import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import _ from 'lodash';

import { Filters } from '@/types/filters';

@Component
export default class FilterComponent extends Vue {
  @Prop()
  value!: Filters;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  filters: Filters = {};

  @Watch('value', { immediate: true, deep: true })
  onValueChange() {
    this.filters = _.assign(this.filters, _.pick(this.value, _.keys(this.filters)));
  }

  clear() {
    Object.keys(this.filters).forEach((k) => {
      this.filters[k] = '';
    });
    this.send();
  }

  send() {
    this.$emit('input', Object.assign({}, this.value, this.filters));
  }
}
