
























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import TreeSelect from '@riophae/vue-treeselect';

import Unit from '@models/firm/Unit';
import Job from '@models/firm/Job';

const firmModule = namespace('firm');

interface StructureFilters {
  affectation: string | string[];
}

@Component({
  components: { TreeSelect },
})
export default class StructureFilter extends Vue {
  @Prop()
  value!: StructureFilters;

  @Prop({ type: Boolean, default: false })
  multiple!: Boolean;

  @firmModule.Getter('getUnits')
  unitList!: Unit[];

  @firmModule.Getter('getOrganization')
  organization!: Unit[];

  get selectedValue() {
    return this.value.affectation ? this.value.affectation : undefined;
  }

  set selectedValue(value) {
    this.value.affectation = value || '';
  }

  get list(): Unit[] {
    const mapJob = (e: Job): any => ({
      id: e.id,
      label: e.label,
    });

    const map = (e: Unit): any => ({
      id: e.id,
      label: e.label,
      children: [...e.children.map(map), ...e.jobs.map(mapJob)],
    });

    return this.organization.map(map);
  }
}
